@import "../../styles/lib.scss";

.Contact {
  margin-top: 50px;
  background-color: white;
  padding: 82px 0;

  @include tablet {
    margin-top: 0;
    padding: 30px;
  }

  &Form {
    margin-top: 48px;
    @include tablet {
      margin-top: 0;
    }
    label {
      margin-bottom: 10px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      gap: 20px;
    }

    &-left {
      width: 50%;

      @include mobile {
        display: none;
      }



      img {
        max-width: 576px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }


    &-right {
      width: 50%;
      @include tablet {
        width: 100%;
      }
      h1 {
        margin-bottom: 20px;
        font-size: 36px;
        line-height: 46px;
        font-weight: 700;
      }
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }
    }
    .Header--bottom--buttons {
      margin-top: 32px;
      button {
        background-color: #1d3682;
        color: white;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
    .form-group {
      margin-top: 20px;
    }
    label {
      margin-bottom: 10px;
    }
  }

  textarea {
    height: 131px !important;
    resize: none;
  }

  &__btn_submit {
    width: 100%;
    // width: 240px !important;
    // max-width: 240px !important;
    @include mobile {
      width: 100% !important;
      max-width: unset !important;
    }
  }

  &__address {
    color: #000;
    font-size: 14px;
    // margin-top: 20px;
    line-height: 21px;
    @include mobile {
      margin-top: 30px;
    }

    a {
      &:hover {
        color: $primary;
      }
    }
    > span {
      font-size: 12px;
      color: #626262;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    h5 {
      margin-top: 10px;
      color: black;
      font-weight: 600;
      margin: 30px 0 25px 0;
    }
    &_infos {
      span {
        display: flex;
        flex-direction: column;
        color: #626262;
        font-size: 14px;
        margin-bottom: 14px;
      }
      &--email,
      &--map,
      &--phone,
      &--clock {
        display: flex;
        align-items: center;
        svg {
          width: 14px;
          height: 13px;
        }
      }
    }
  }
}
