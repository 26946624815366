.Button {
  width: 100%;
  background-color: #242422;
  color: white;
  padding: 12px 0;
  border-radius: 3px;
  border: 1px solid transparent;

  &:hover {
    color: #242422;
    background-color: white;
    border: 1px solid #242422;
  }
}
