@import "../../../styles/lib";

.MainTemplate {
  padding: 10vh 0;
  &__home {
    z-index: 2;
    padding-bottom: 0;
  }
  @include tablet {
    &__home {
      padding: 0;
      z-index: 1;
    }
  }
  // &__white {
  //   background-color: white;
  // }
}
