@import "../../../styles/lib";

.Header {
  display: flex;
  width: 100%;
  padding: 40px;
  height: 726px;
  position: relative;
  flex-direction: column;
  align-items: flex-start !important;
  padding-bottom: 195px;

  &__backgroundImg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    video {
      width: 100%;
      height: unset !important;
    }
    @media only screen and (max-width: 1185px) {
      video {
        width: unset !important;
        height: 100%;
      }
    }

    @include tablet {
      video {
        width: unset !important;
        height: 100%;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--top {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }
  &--bottom {
    z-index: 1;
    margin-top: 142px;
    margin-left: 107px;
    margin-right: 107px;
    @include tablet {
      margin-left: 0;
      margin-right: 0;
      h1 {
        font-size: 32px;
        line-height: 42px;
      }
    }
    h1 {
      color: white;
      font-size: 50px;
      font-weight: 700;
      line-height: 60px;
      margin-bottom: 20px;
      @include tablet {
        font-size: 32px;
        line-height: 42px;
      }
    }
    p {
      color: white;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 53px;

      @include tablet {
        font-size: 17px;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 30px;
      }
    }
    &--buttons {
      display: flex;
      button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 26px;
        gap: 10px;
        padding: 12px 26px;
        background: #ffffff;
        border-radius: 6666px;
        order: 0;
        border: 1px solid #fff;
        font-weight: 700;
        font-size: 17px;
        line-height: 23px;
        text-align: center;
        color: #1f46be;
        @include tablet {
          font-size: 15px;
          line-height: 20px;
        }
      }
      &-rev {
        margin-left: 12px;
        color: #fff !important;
        background-color: transparent !important;
      }
    }
  }
  @include tablet {
    justify-content: space-between;
  }
}
