@import "../../styles/lib.scss";

.TopSale {
  background-color: white;
  margin: 0 32px;
  padding: 56px 0;
  @include tablet {
    padding: 32px 20px;
    margin-left: 9px;
    margin-right: 9px;
  }
}
