@import "../../../styles/lib";

.OtherHeader {
  display: flex;
  width: 100%;
  background-color: #e7e9f0;
  padding: 40px;
  flex-direction: column;

  &__contact {
    background-color: white;
    padding: 40px;
    border-bottom: 1px solid #f1f1f1;
  }

  .NavigationBarItem__link {
    color: black;
  }

  .NavigationBarItem__line {
    color: black;
  }

  &--top {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    > div {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &--bottom {
    margin-top: 142px;

    h1 {
      color: black;
      font-size: 44px;
      font-weight: 700;
      line-height: 60px;
      margin-bottom: 20px;
      @include tablet {
        font-size: 32px;
        line-height: 42px;
      }
    }
    p {
      color: black;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 53px;
    }
    &--buttons {
      display: flex;
      button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 26px;
        gap: 10px;
        padding: 12px 26px;
        background: #ffffff;
        border-radius: 6666px;
        order: 0;
        border: 1px solid #fff;
        font-weight: 700;
        font-size: 17px;
        line-height: 23px;
        text-align: center;
        color: #1f46be;
      }
      &-rev {
        margin-left: 12px;
        color: #fff !important;
        background-color: transparent !important;
      }
    }
  }
  @include tablet {
    justify-content: space-between;
  }
}
