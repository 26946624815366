@import "../../styles/lib";

.NavigationBar {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include tablet {
    justify-content: flex-end;
  }
  .NavigationBarItem__line {
    color: white;
    margin-right: 20px;

    @include tablet {
      display: none;
    }
  }


  &__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    @include tablet {
      z-index: 9999;
      display: flex;
      width: 100%;
      height: 350px;
      position: absolute;
      top: 100px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      grid-gap: 0px;
    }
  }

  &__list.active {
    @include tablet {
      height: 100vh;
      overflow-y: hidden;
      left: 0;
      top: 100px;
      opacity: 1;
      background-color: white;
      transition: all 0.5s ease;
      z-index: 9999;
      align-content: center;
      padding-left: 31px;
      margin-right: 0;
      padding-top: 40px;
      display: flex;
      justify-content: left;
    }
  }
  &__icon {
    width: 30px;
    height: 30px;
  }
  &__mobile {
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__contact {
    white-space: nowrap;
    margin-left: auto;
    margin-right: 30px;
    color: #000;

    @include tablet {
      margin: 10px auto 20px 0;
    }
  }

  &__email {
    white-space: nowrap;
    margin-right: 30px;
    color: #000;

    @include tablet {
      margin: 10px auto 20px 0;
    }
  }
}
