$font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: "Ubuntu", sans-serif;
$font-family-secondary: "Open Sans", sans-serif;
$font-family-third: "Old Standard TT", serif;

$primary: #888484;
$effect: #f8ecc7;
$primaryLight: #242422;
$textColor: #8c8b8b;
$error: #f45050;

$mq-small: 767px;
$mq-medium: 992px;
$d-small: 1024px;
$d-medium: 1240px;

/*===================================
=            TRANSITIONS            =
===================================*/
/// Google Material Design cubic bezier transition
/// @type Transition
$transition1: all 0.3s cubic-bezier(0.3, 0, 0.2, 1);
/// Google Material Design cubic bezier transition slower
/// @type Transition
$transition2: all 0.6s cubic-bezier(0.6, 0, 0.2, 1);
/*=====  End of TRANSITIONS  ======*/
