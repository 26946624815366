@import "./src/styles/lib";

.ThemeContainer {
  margin: 0 auto;
  max-width: 1290px;
  padding-right: 15px;
  padding-left: 15px;

  @include mobile {
    width: 100%;
    padding: 15px;
  }

  @include tablet {
    width: 100%;
    padding: 15px;
    max-width: none;
    margin: 0;
  }
}
