@import "../../styles/lib";

.Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-family: $font-family-third;
  &__icon {
    width: 80px;
    height: 80px;
    margin: 5px 3px;
  }
  &__name {
    white-space: nowrap;
    color: black;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    margin-left: 15px;
  }

  &__invert {
    .Logo__name {
      color: white;
    }
  }
}
