//====================== IMPORTANT NOTE: Do not change import order! =====================//

// Reset styling.
@import "reset";

//Include lib. Override bootstrap and declare other variables.
@import "lib";

//Include bootstrap.
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
//@import "bootstrap/bootstrap";


.container {
  max-width: unset !important;
  padding-left: unset !important;
  padding-right: unset !important;
}

//Import other styles.
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Montserrat&family=Old+Standard+TT:wght@400;700&display=swap");


* {
  font-family: "Ubuntu",sans-serif !important;
}
.animation-enter {
  opacity: 0;
  transform: translateY(-10%);
  transition: all 0.3s;
}
.animation-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s;
}
.animation-exit {
  opacity: 1;
  transition: all 0.3s;
}
.animation-exit-active {
  opacity: 0;
  transform: translateY(-10%);
  transition: all 0.3s;
}
