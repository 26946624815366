@import "../../styles/lib.scss";

.Home {
  width: 100%;
  margin-top: -150px;
  @include tablet {
    margin-top: -40px;
  }
}


@media (max-width: 992px) {
  .Header__backgroundImg video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}
