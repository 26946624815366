@import "../../styles/lib.scss";

.Select {
  position: relative;

  @include desktop {
    max-width: 300px;
  }

  select {
    display: block;
    height: 44px;
    padding: 0 15px !important;
    border: 1px solid #e8e8e8;
    border-radius: 5px !important;
    color: #4a5568 !important;
    width: 100%;
    outline: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    // text-align: center;
    //border-radius: 20px;
    //background-size: 10px;
    //background-color: $gray;
    //background-repeat: no-repeat;
    //background-position: right 15px center;
    //background-image: url("./../../../assets/icons/chevron-down.svg");

    @include mobile {
      //height: 56px;
      //border-radius: 27px;
      display: inline-block;
      //padding-left: 27px;
      //background-position: right 24px center;
      //font-size: 12px;
    }
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 13px;

    .Spinner {
      background-color: black;
      padding: 3px;

      @include mobile {
        margin-right: 10px;
        padding: 5px;
      }
    }
    .text-secondary {
      color: black !important;
    }
  }
}
