.App {
  width: 100%;
  display: flex;
  height: 100vh;
  justify-content: space-between;
  flex-direction: column;
  .Wrapper {
    margin-top: 11vh;
  }
}
