@import "../../styles/lib.scss";

.OurServices {
  padding: 82px 0;

  @include tablet {
    padding: 47px 30px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      text-align: center;
    }

    @include tablet {
     &-subtitle {
       margin-top: 20px;
     }
    }



    h1 {
      font-size: 34px;
      font-weight: 700;
      line-height: 46px;
      color: black;
      @include tablet {
        font-size: 28px;
        line-height: 28px;
      }
    }
    span {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      color: #343434;
      @include tablet {
        font-size: 17px;
        line-height: 27px;
      }
    }
    &_items {
      display: grid;
      grid-gap: 22px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 65px;
      width: 100%;

      @include tablet {
        margin-top: 30px;
        grid-template-columns: repeat(1, 1fr);
      }

      &_item {
        display: flex;
        background-color: white;
        border-radius: 16px;
        padding: 20px 23px;
        justify-content: space-between;
        align-items: center;
        &[data-opened="true"] {
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &:hover {
          cursor: pointer;
        }
        &--left {
          display: flex;
          align-items: center;
          span {
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
          }
          &-phone {
            margin-right: 20px;
          }
        }
        &--right {
          &-union {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #f0f1f2;
            border-radius: 100%;
            padding: 10px;
          }
        }
        &__desc {
          background-color: white;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          padding: 20px 23px;
        }
      }
    }
  }
}
