@import "../../styles/lib.scss";

.SingleCategory {
  margin: -10vh 0;
  padding-bottom: 130px;
  &__wrapper {
    @include tablet {
      padding: 0 30px;
    }
    &_top {
      display: flex;
      position: relative;
      height: 700px;
      align-items: flex-end;
      &--title {
        margin: 0 auto;
        max-width: 1392px;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        z-index: 4;
        margin-bottom: 60px;
      }
      &--img {
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      h1 {
        font-size: 44px;
        line-height: 60px;
        font-weight: 700;
        z-index: 3;
        color: white;
        @include tablet {
          font-size: 32px;
          line-height: 42px;
        }
      }
    }
    &_bottom {
      display: flex;
      margin-top: 96px;
      flex-direction: column;
      @include tablet {
        margin-top: 53px;
      }

      h1 {
        font-size: 36px;
        line-height: 44px;
        font-weight: 700;
        color: #101828;
        margin-bottom: 24px;
        @include tablet {
          font-size: 28px;
          font-weight: 700;
          line-height: 37px;
          margin-bottom: 18px;
        }
      }
      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #475467;
        @include tablet {
          font-size: 17px;
          line-height: 27px;
          margin-bottom: 0;
        }
      }
      &-items {
        display: flex;
        flex-direction: column;
        margin-top: 48px;

        &--item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #e7e9f0;
          padding: 45px 100px;
          border-radius: 12px;
          margin-bottom: 23px;
          @include tablet {
            flex-direction: column;
            padding: 30px;
            align-items: flex-start;
          }
          &--middle {
            margin: 0 40px;
            @include tablet {
              margin: 50px 0 0 0;
            }
            h1 {
              font-size: 32px;
              font-weight: 700;
              line-height: 30px;
              @include tablet {
                font-size: 24px;
                line-height: 34px;
              }
            }
            p {
              font-size: 17px;
              line-height: 27px;
              font-weight: 500;
              color: #475467;
              margin-top: 28px;
              @include tablet {
                font-size: 17px;
                line-height: 27px;
                margin-bottom: 0;
              }
            }
          }
          &--right {
            align-self: flex-end;
            background-color: #fff;
            padding: 20px;
            border-radius: 666px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              cursor: pointer;
            }
            @include tablet {
              align-self: flex-start;
              margin-top: 44px;
            }
          }
        }
      }
    }
  }
}
