@import "../../styles/lib";

.AboutUs {
  margin: -10vh 0;
  padding: 10vh 0;
  background-color: white;

  @include tablet {
    padding: 50px 30px;
  }

  &__top {
    background-color: white;

    &__wrapper {
      display: flex;
      flex-direction: column;
      text-align: center;
      h1 {
        color: #101828;
        font-size: 36px;
        font-weight: 700;
        line-height: 44px;
        margin-bottom: 20px;
        @include tablet {
          font-size: 28px;
          line-height: 37px;
          font-weight: 700;
          margin-bottom: 18px;
        }
      }
      p {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: #475467;
        @include tablet {
          font-size: 17px;
          line-height: 27px;
          font-weight: 500;
        }
      }
      &__bottom {
        display: flex;
        flex-direction: column;
        margin-top: 64px;
        @include tablet {
          margin-top: 30px;
        }
        &--img {
          margin-bottom: 80px;
          @include tablet {
            margin-bottom: 40px;
          }
        }
        &--items {
          display: grid;
          grid-gap: 30px;
          grid-template-columns: repeat(3, 4fr);
          @include tablet {
            grid-template-columns: repeat(1, 4fr);
          }
          &-item {
            background-color: #f9fafb;
            display: flex;
            flex-direction: column;
            padding: 24px;
            border-radius: 10px;
            align-items: baseline;

            @include tablet {
              text-align: initial;
            }

            &-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #ebedf3;
              padding: 14px;
              border-radius: 10px;
              margin-bottom: 64px;
            }
            span {
              font-size: 20px;
              font-weight: 700;
              line-height: 30px;
              margin-bottom: 8px;
            }
            p {
              color: #475467;
              font-size: 16px;
              line-height: 24px;
            }
            label {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              margin-top: 20px;
              color: #1d3682;
            }
          }
        }
      }
    }
  }
  &__bottom {
    margin-top: 95px;
    padding: 96px 0;
    background-color: #f6f6f6;

    @include tablet {
      margin: 37px -30px;
      padding: 50px 30px;
    }

    &__wrapper {
      &--desc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 96px;
        @include tablet {
          text-align: center;
          margin-bottom: 40px;
        }
        h1 {
          font-size: 36px;
          font-weight: 700;
          line-height: 44px;
          color: #101828;
          margin-bottom: 20px;
          @include tablet {
            font-size: 28px;
            line-height: 37px;
            font-weight: 700;
            margin-bottom: 18px;
          }
        }
        p {
          font-size: 20px;
          line-height: 30px;
          color: #475467;
          font-weight: 500;
          @include tablet {
            font-size: 17px;
            line-height: 27px;
            font-weight: 500;
          }
        }
      }
      &--top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 96px;
        @include tablet {
          margin-bottom: 40px;
          flex-direction: column-reverse;
        }
        &-right {
          @include tablet {
            margin-bottom: 36px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &-left {
          display: flex;
          flex-direction: column;
          margin-right: 96px;
          align-items: baseline;
          @include tablet {
            margin-right: 0;
          }
          &:nth-child(2) {
            margin-left: 96px;
            margin-right: 0;
            @include tablet {
              margin-left: 0;
            }
          }
          &-link {
            padding: 14px;
            border: 8px solid #ececec;
            border-radius: 667px;
            background-color: white;
            margin-bottom: 32px;
          }
          h1 {
            color: #101828;
            font-size: 32px;
            line-height: 38px;
            font-weight: 700;
            margin-bottom: 16px;
            @include tablet {
              font-size: 28px;
              line-height: 37px;
              font-weight: 700;
            }
          }
          p {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            color: #475467;
            @include tablet {
              font-size: 17px;
              line-height: 27px;
              font-weight: 500;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
