@import "../../../styles/lib";

.NavigationBarItem {
  display: flex;
  align-items: center;
  @include tablet {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 30px 0px;
  }

  &__link {
    color: #fff;
    margin-right: 20px;
    text-decoration: none;
    white-space: nowrap;
    font-family: $font-family-secondary;
    font-size: 17px;
    line-height: 21px;
    padding: 0 5px;

    &:hover {
      color: white;
    }
    &:focus {
      color: white;
    }

    @include tablet {
      margin-right: 0;
      color: #000;
    }
  }
  &__active {
    @include tablet {
      color: #000;
    }
    color: white;
    font-weight: 700;
  }
}
