@import "./../../styles/_lib";

.SlideShow {
  position: relative;


  &-head{
    @include tablet {
      flex-direction: column;
    }
  }


  &--category {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    align-items: flex-end;
    &-img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      min-height: 210px;

      img {
        object-fit: cover;
      }
    }
    &-desc {
      display: flex;
      flex-direction: column;
      padding: 25px 16px;
      background-color: #0c39c1;
      z-index: 3;
      opacity: 0.8;
      width: 100%;
      @include tablet {
        padding: 15px 12px;
      }
      span {
        font-size: 24px;
        font-weight: 700;
        line-height: 34px;
        color: #fff;
        @include tablet {
          font-size: 20px;
          line-height: 34px;
          font-weight: 700;
        }
      }
      p {
        font-size: 17px;
        line-height: 27px;
        font-weight: 400;
        color: #fff;
        margin-top: 4px;
        margin-bottom: 0;
        @include tablet {
          font-size: 14px;
          line-height: 26px;
          font-weight: 400;
          margin-top: 2px;
        }
      }
    }
  }

  &__title {
    &_t {
      font-size: 34px;
      font-weight: 700;
      line-height: 46px;
      margin-bottom: 10px;
      @include tablet {
        font-size: 28px;
        line-height: 37px;
        font-weight: 700;
      }
    }
    span {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      color: #343434;
      @include tablet {
        font-size: 17px;
        line-height: 27px;
      }
    }
  }

  @include mobile {
    font-size: 20px;
    margin-top: 30px;
  }
  @include tablet {
    margin-top: 30px;
    h2 {
      font-size: 20px;
    }
  }
  &-box {
    outline: 0;
    margin-right: 10px;
    margin-left: 10px;
    width: 332px;
    height: 378px;

    img {
      width: 100%;
      height: 100%;
    }

    @include mobile {
      margin-left: 10px;

      .Product__image__holder {
        height: 199px;
        border-radius: 20px;
      }
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    max-height: 210px;

    @include mobile {
      max-height: 114px;
    }

    @include tablet {
      max-height: 171px;
    }
  }

  &__logo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background: rgba(#000, 0.5);
    transition: 300ms ease;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }

    &:hover {
      background: rgba(#000, 0.7);
      @include mobile {
        background: rgba(#000, 0.5);
      }
      @include tablet {
        background: rgba(#000, 0.5);
      }
    }
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    color: #fff;
  }

  &__wrapper {
    display: block;
    // background-color: #f3f2ec;
    padding: 10px 0;
    align-items: center;
    z-index: 2;
    margin-top: 40px;

    @include tablet {
      margin-top: 23px;
    }

    &--title {
      padding-bottom: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include tablet {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include mobile {
        padding-left: 20px;
        padding-right: 20px;
        flex-wrap: wrap;
        margin: 0 -15px;
      }

      &-arrow {
        transition: 0.25s ease;
        cursor: pointer;
        &:first-child {
          margin-right: 20px;
        }
        &:hover {
          transition: 0.25s ease;

          g {
            fill: #ffffff;
          }
          path {
            fill: #000000;
          }
        }
      }
    }

    @include tablet {
      padding: 10px 0 0 0;
      // margin: 0 -15px;
    }
    @include mobile {
      padding: 10px 0 0 0;
      // margin: 0 -15px;
    }

    .Product__brand-name {
      background-color: transparent;
    }
  }
  /* smaller, dark, rounded square */
  .flickity-button {
    transition: all 0.3s;
  }
  .flickity-button:hover {
    transition: all 0.3s;
  }

  .flickity-prev-next-button {
    width: 76px;
    height: 76px;
    border-radius: 0;
    background-color: transparent;
    @include mobile {
      display: none;
    }
  }
  /* icon color */
  .flickity-button-icon {
    fill: black;
  }
  /* position outside */
  .flickity-prev-next-button.previous {
    left: 0;
    z-index: 2;
  }
  .flickity-prev-next-button.next {
    right: 0;
    z-index: 2;
  }
}

@import "./../../styles/_lib";

.HomePageSlideShow {
  &-box {
    outline: 0;
    margin-right: 15px;
    margin-left: 15px;
    max-width: 332px;

    img {
      width: 100%;
      min-height: 210px;
    }

    @include mobile {
      width: 160px;
      margin-right: 5px;
      margin-left: 5px;
      img {
        width: 160px;
        height: 199px;
      }
      .ProductItem__image__holder {
        height: 199px;
      }
    }
    &-buttons-prev,
    &-buttons-next {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      top: 0;
      bottom: 0;
      z-index: 2;
      margin-top: -17px;
      pointer-events: none;
      &-wrapper {
        width: 76px;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        pointer-events: all;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &-buttons-next {
      right: 0;
    }
    &-buttons-prev {
      left: 0;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    max-height: 210px;

    @include mobile {
      max-height: 114px;
    }

    @include tablet {
      max-height: 171px;
    }
  }

  &__logo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background: rgba(#000, 0.5);
    transition: 300ms ease;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }

    &:hover {
      background: rgba(#000, 0.7);
      @include mobile {
        background: rgba(#000, 0.5);
      }
      @include tablet {
        background: rgba(#000, 0.5);
      }
    }
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    color: #fff;
  }

  &__wrapper {
    display: block;
    background-color: #f3f2ec;
    padding: 37px 0;
    align-items: center;
    z-index: 2;
    // .container {
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     border: 1px solid #f3f2ec;
    //     background-color: #f3f2ec;
    //     z-index: 1;
    //     width: 30px;
    //     height: 350px;
    //     box-shadow: -50px 45px 35px 66px #f3f2ec;
    //     right: -20px;
    //     top: 0;
    //   }
    //   &:before {
    //     content: "";
    //     position: absolute;
    //     border: 1px solid #f3f2ec;
    //     background-color: #f3f2ec;
    //     z-index: 1;
    //     width: 30px;
    //     height: 350px;
    //     box-shadow: 50px 45px 35px 65px #f3f2ec;
    //     left: -20px;
    //     top: 0;
    //   }
    //   @include tablet {
    //     &:after,
    //     &:before {
    //       display: none;
    //     }
    //   }
    //   @include mobile {
    //     &:after,
    //     &:before {
    //       display: none;
    //     }
    //   }
    //   @media only screen and (max-width: 1335px) {
    //     &:after {
    //       right: 0;
    //     }
    //     &:before {
    //       left: 0;
    //     }
    //   }
    // }
    &--title {
      padding-bottom: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include tablet {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include mobile {
        padding-left: 20px;
        padding-right: 20px;
        flex-wrap: wrap;
      }

      &-arrow {
        transition: 0.25s ease;
        cursor: pointer;
        &:first-child {
          margin-right: 20px;
        }
        &:hover {
          transition: 0.25s ease;

          g {
            fill: #ffffff;
          }
          path {
            fill: #000000;
          }
        }
      }
    }

    @include tablet {
      padding: 10px 0 42px 0;
    }
    @include mobile {
      padding: 10px 0 42px 0;
    }

    .ProductItem__brand-name {
      background-color: transparent;
    }
  }
  /* smaller, dark, rounded square */
  .flickity-button {
    background: #ffffff;
    transition: all 0.3s;
  }
  .flickity-button:hover {
    background: #ffffff;
    transition: all 0.3s;
  }

  .flickity-prev-next-button {
    width: 76px;
    height: 76px;
    border-radius: 0;
    margin-top: -7px;
    @include mobile {
      display: none;
    }
  }
  /* icon color */
  .flickity-button-icon {
    fill: black;
  }
  /* position outside */
  .flickity-prev-next-button.previous {
    left: 0;
    z-index: 2;
  }
  .flickity-prev-next-button.next {
    right: 0;
    z-index: 2;
  }
  &--mobile {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .HomePageSlideShow--mobile {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.HomeSlider__item {
  margin-right: 15px;
  margin-bottom: 15px;
  width: 183px;

  @include tablet {
    width: 266px;
    height: 303px;
    margin-bottom: 0;
  }
}
.disable-pointer-events {
  pointer-events: none;
}
