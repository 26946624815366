@import "../../styles/lib";

.Page404 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;

  span {
    font-size: 60px;
    line-height: 60px;
    font-weight: 400;
  }
  p {
    margin-top: 10px;
    line-height: 75px;
    color: #7b7b7b;
  }
  h1 {
    color: #1d3682;
    font-size: 120px;
    line-height: 120px;
  }
  button {
    max-width: 217px;
    margin-top: 25px;
  }

  @include tablet {
    p {
      line-height: unset;
      margin-top: 50px;
    }
  }
  @include mobile {
    p {
      line-height: unset;
      margin-top: 50px;
    }
  }
}
